import React from 'react';
import ReactDOM from 'react-dom';
//import { render } from 'react-snap';
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import { ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CssBaseline from "@material-ui/core/CssBaseline";
import CookieConsent, { Cookies } from "react-cookie-consent";
//import { darktheme, lighttheme } from './theme';
//import {createStore} from 'redux';
//import allReducers from './reducers';
import {Provider} from 'react-redux';
import mainstore from './store';
import { JssProvider } from "react-jss"; 


//import ThemeContext from './ThemeContext';
//import darklightreducer from './Components/darklightreducer';, {useReducer}

//const [state, dispatch] = useReducer(darklightreducer, {  isDark: false});




//const isDark = false;
class DebugRouter extends Router {
  constructor(props){
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

const rootElement = document.getElementById("root");

const CookieTemp = (
<React.Fragment>  
<CookieConsent
location="bottom"
buttonText="I understand"
cookieName="dateandtimeaccept"
debug= "true"


style={{ background: "#2B373B" }}
buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
expires={150}
>
{"         "} This website uses cookies to enhance the user experience.{" "}
<span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
</CookieConsent>
</React.Fragment>
);
const jssStyles = document.querySelector("#jss-server-side");
if (jssStyles) {
  jssStyles.parentElement.removeChild(jssStyles);
}
// Grab the state from a global variable injected into the server-generated HTML
// const preloadedState = window.__PRELOADED_STATE__;

// // Allow the passed state to be garbage-collected
// delete window.__PRELOADED_STATE__;

// // Create Redux store with initial state
// const store = createStore(mainstore, preloadedState);

// // Tell react-snap how to save Redux state
// window.snapSaveState = () => ({
//   __PRELOADED_STATE__: store.getState()
// });
// const checked =true;
// const location = useLocation();

// ReactDOM.render(<Provider store={mainstore}><React.StrictMode><Paper><Router><App /></Router></Paper></React.StrictMode></Provider>, rootElement);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Provider store={mainstore}><React.StrictMode><JssProvider id={{ minify: true }}><CssBaseline /><Paper><Router><App /></Router></Paper></JssProvider></React.StrictMode></Provider>, rootElement);
} else {
  ReactDOM.render(<Provider store={mainstore}><React.StrictMode><JssProvider id={{ minify: true }}><CssBaseline /><Paper><Router><App /></Router></Paper></JssProvider></React.StrictMode></Provider>, rootElement);
}

// ReactDOM.render(
  
//   <Provider store={mainstore}>
//   <React.StrictMode>
    
    
//       <Paper>
//       <App />
//       </Paper>
    
    
//   </React.StrictMode>
//   </Provider>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
