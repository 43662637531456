import React,{ Component} from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import allcities from '../data/allcities.json';
//import {allcities} from '../data/allcities.js';
//import cityroutes from './Cityandzoneroutes.json';
import { List } from "react-virtualized";
//import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
//import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
//import Button from '@material-ui/core/Button';
//import SlideshowIcon from '@material-ui/icons/Slideshow';
//import PausePresentationIcon from '@material-ui/icons/PausePresentation';
//import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
//import PlayArrowIcon from '@material-ui/icons/PlayArrow';
//import PauseIcon from '@material-ui/icons/Pause';
// import StopIcon from '@material-ui/icons/Stop';
// import { MuiPickersUtilsProvider,   KeyboardTimePicker} from '@material-ui/pickers';
// import MomentUtils from "@date-io/moment";

//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';
//import { format } from 'moment';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
import  moment from 'moment-timezone';
//import cityroutes from './Cityandzoneroutes.json'
//import timeZone from 'moment-timezone';

import { setClockZone, changeClockZone, addClock, deleteClock, setClockMainZone} from '../actions/clockActions';
import RadioButtonsSize from './RadioButtonsSize';

//const allcities  = React.lazy(() => import('../data/allcities.json'));


const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={300}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});
class ClockzoneComponent extends Component  {

  componentDidMount() {
    
  }
  componentWillUnmount(){
    
  }
  
render() {
  
  //const time = this.props.time;
  //const isAlarmON = this.props.running;
  //const stamp = this.props.stamp;
  //const currentzone = moment.tz.guess();
  //const utcTime = zonedTimeToUtc(time, currentzone);
  //const zonedTime = utcToZonedTime(time, currentzone);
  var m = moment().utcOffset(0);
  m.set({hour:0,minute:0,seconds:0});
  m.toISOString();
  let timezones = [];
  //let countries = [];
  moment.tz.names().map(timezone => 
    timezones.push(timezone));
    let alltimezones =[];
    let alltimezoneslist= [];
    
    var count = allcities.length;
    
    for(var i = 0; i < count; i++){
      alltimezones[i] = allcities[i];
    }
    alltimezones.map(item =>
      alltimezoneslist.push(item.asciiname+","+item.country+"("+item.timezone+")")
    );
  
    let itemsbyname= [];
  let timezonebyname= [];
  let numberbyname= [];
  alltimezones.map(item =>
    itemsbyname[item.asciiname+","+item.country+"("+item.timezone+")"]= {city: (item.asciiname+","+item.country), number :item.number}
  );
  alltimezones.map(item =>
    timezonebyname[item.asciiname+","+item.country+"("+item.timezone+")"]= item.timezone
  );
   
  // cityroutes.map(timezone => 
  //     timezones.push(timezone));
    
  // cityroutes.map(timezone => 
  //     countries.push(timezone));
  //const momenttime = moment(time);
  const clockarray= this.props.clockarray;
  const setsize= this.props.setsize;
  const actiontype = this.props.actiontype;
  const clockkey = this.props.clockkey;
   
return ( 
  
    
    <Box display="flex"   container="true" direction="column" justify="space-around" alignItems="center" alignContent="center">
    <Paper elevation={2} > 

    <Grid item xs={3}>
      <Autocomplete
      id="timezone-select"
      value={clockarray.timezone}
      ListboxComponent={ListboxComponent}
      openOnFocus
      selectOnFocus
      inputValue={clockarray.timezone}
      onInputChange={(event, newInputZone) => {if (alltimezoneslist.some(item => item == newInputZone)){if (actiontype== "change"){if(clockkey==0){this.props.setClockMainZone({size:setsize, timezone:timezonebyname[newInputZone], item:itemsbyname[newInputZone]});}else {this.props.changeClockZone({size:setsize, timezone:timezonebyname[newInputZone], item:itemsbyname[newInputZone], clockkey:clockkey})};}
       else {this.props.setClockZone({size:setsize, timezone:timezonebyname[newInputZone], item:itemsbyname[newInputZone]});}}
      }}
      style={{ width: 300 }}
      options={alltimezoneslist}
      
      autoHighlight
      getOptionLabel={(option) => option}
      renderOption={(option) => (
        <React.Fragment>
          
           <Typography variant="subtitle2" align="left" noWrap>{option}</Typography>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={(clockkey==0)?<Typography variant="button" component="h5">Choose main timezone/city</Typography>: <Typography variant="button" component="h5">Choose timezone/city</Typography>}
          variant="filled"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
        )}
    />
            <Typography variant="h5" component="h5" color="textSecondary">
          
          <br></br>
          <RadioButtonsSize action={(actiontype== "change") ? "change": "add"} clockkey={clockkey}/>              

          </Typography>
</Grid>
        
        


        
          </Paper>
<Grid >

   
</Grid>
      </Box>
      
  )
}
}
const mapStateToProps = state => ({
  keys:state.clock.keys,
  clockarray:state.clock.clockarray,
  setsize:state.clock.setsize,
  maintimezone:state.clock.maintimezone
});


const mapDispatchToProps = {
  setClockZone,     
  changeClockZone,
  addClock,
  deleteClock,
  setClockMainZone
  };
export default connect(mapStateToProps, mapDispatchToProps)(ClockzoneComponent);
