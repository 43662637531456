import React from 'react';
import {useSelector} from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import {setClockSize, changeClockSize} from '../actions/clockActions';
import mainstore from '../store';

export default function RadioButtonsSize(props) {
  //clockkey is passed through props. all values for clock are extracted from state using key
  const clocksize = useSelector(state => state.clock.setsize);
  const mainzone = useSelector(state => state.clock.maintimezone);
  const clockkey = props.clockkey;
  //for default value if no value is returned from selector
  const clockzone = useSelector(state => state.clock.clockarray[clockkey]) || {size:clocksize, timezone:mainzone, item:{city: (" "), number:"0"}, clockkey:clockkey};
  const [hydrated, setHydrated] = React.useState(false);
  const action = props.action;
  const handleChange = (event) => {
    if (action =="change"){
      mainstore.dispatch(changeClockSize({size:Number(event.target.value),timezone:clockzone.timezone, item:clockzone.item, clockkey:clockkey}));
    } else {
    mainstore.dispatch(setClockSize(Number(event.target.value)));
    }
  };
  React.useEffect(() => {
    setHydrated(true);
  }, []);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Size</FormLabel>
      <RadioGroup aria-label="size" name="size" value={clockzone.size} onChange={handleChange}>
      {hydrated && (
         <>
        <FormControlLabel value={12} control={<Radio size="small"/>} label={<Typography variant="button" component="h5">Large</Typography>} />
        <FormControlLabel value={6} control={<Radio size="small"/>} label={<Typography variant="button" component="h5">Medium</Typography>}/>
        <FormControlLabel value={4} control={<Radio size="small"/>} label={<Typography variant="button" component="h5">Small</Typography>} />
        </>
      )}
      </RadioGroup>
    </FormControl>
  );
}
