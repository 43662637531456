import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
//import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
// import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
// import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CopyIconSvg from '../SvgIcons/CopyIconSvg';
import Zoom from '@material-ui/core/Zoom';
//import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import EditIconSvg from '../SvgIcons/EditIconSvg';
import CloseIconSvg from '../SvgIcons/CloseIconSvg';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
//import { format } from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import moment from 'moment';
//import timeZone from 'moment-timezone';
import  moment from 'moment-timezone';
// import moment from 'moment';
// import 'moment-timezone';
import mainstore from '../store';
import {deleteClock} from '../actions/clockActions';
import ClockzoneComponent from './ClockzoneComponent';
//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';

//const ClockzoneComponent  = React.lazy(() => import('./ClockzoneComponent'));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  time: {
    padding: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
  },
  small: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grids:
  {
    height: "100%",
    padding: theme.spacing(1),
   },
   clipbutton:
   {
     height: "100%",
     padding: theme.spacing(1),
     color: theme.palette.text.secondary,
    },
}));




function ChangeRemove(props) {
const clockkey= props.clockkey;
const [open, setOpen] = React.useState(false);
const handleOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
if (clockkey === 0) {
  return (
  <Box display="flex" alignContent="flex-start" alignItems="flex-start" justifyContent="flex-end">
                <Tooltip title="Edit Size/Timezone"  TransitionComponent={Zoom} placement="top">
           <IconButton onClick={handleOpen} color="default" aria-label="Change Clock" component="span" size="small">
         
          
      <EditIconSvg style={{ fontSize: 15 }} />
 
        </IconButton>
        </Tooltip>
        <Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
>
<React.Suspense fallback={<CircularProgress color="secondary" />}>
<ClockzoneComponent  actiontype="change" clockkey={clockkey}/>
</React.Suspense>
</Dialog>
        </Box>
  
)
} else {
  return (
    <Box display="flex" alignContent="flex-start" alignItems="flex-start" justifyContent="flex-end">
                  <Tooltip title="Edit Size/Timezone"  TransitionComponent={Zoom} placement="top">
             <IconButton onClick={handleOpen} color="default" aria-label="Change Clock" component="span" size="small">
           
            
        <EditIconSvg style={{ fontSize: 15 }} />
   
          </IconButton>
          </Tooltip>
          <Tooltip title="Remove Clock"  TransitionComponent={Zoom} placement="top">
          <IconButton onClick={() => { mainstore.dispatch(deleteClock(clockkey))}} color="default" aria-label="Remove Clock" component="span" size="small">
            <CloseIconSvg style={{ fontSize: 15 }}/>
          </IconButton>
          </Tooltip>
          <Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
>
<React.Suspense fallback={<CircularProgress color="secondary" />}>
<ClockzoneComponent actiontype="change" clockkey={clockkey}/>
</React.Suspense>
</Dialog>
          </Box>
    
  )}
};


export default function Clock(props) {
  const classes = useStyles();
  const size = props.size;
  const clockkey = props.clockkey;
  const [time, setTime] = useState(Date.now());
  const currentzone = moment.tz.guess();
  const clockzone = props.clockzone;
  const clockitem = props.clockitem;
  
  const isTwelve = useSelector(state => state.isTwelve);
  //const utcTime = zonedTimeToUtc(time, currentzone);
  //const zonedTime = utcToZonedTime(time, currentzone);
  const momenttime = moment(time);
  const matches420 = useMediaQuery('(min-width:420px)');
  const matches1140 = useMediaQuery('(min-width:1140px)');
  const matches1290 = useMediaQuery('(min-width:1290px)');
  const matches1010 = useMediaQuery('(min-width:1010px)');
  

  useEffect(() => {
    const timeinterval = setInterval(() => setTime(Date.now()), 100);
    return () => {
      clearInterval(timeinterval);
    };
  }, []);
  //const bull = <span className={classes.bullet}>•</span>;

  switch(size){
  case 12:
          return (
            <Grid item xs={size} className={classes.root}>
                
                  <Paper elevation={6} className={classes.paper}>
                 
                  <Box display="flex" alignContent="stretch" flexWrap="wrap" alignSelf="stretch" justifyContent="space-between">
                  
                  <Grid item xs={matches1010 ? 2 : 3}>
                  
                  
                  <Typography className={classes.title} color="textSecondary" gutterBottom align="justify">
                  <br />
                  <b> {clockzone.split("/").join(" - ")} </b>
                    <br />
                    {clockitem.city.split(",").join(" ")}<br></br>
                      {moment.tz(clockzone).format('UTCZ')}
                      {moment.tz(clockzone).format('(zz)')}
                      <br />
                      
                     

       
                      </Typography>
                      
                     
                      </Grid>
                      
                      <Paper elevation={2} className={classes.time}> 
                      <Typography variant="h2" component="h2" color="textSecondary">
                        <b>
                        {isTwelve ? moment.tz(clockzone).format('hh'):moment.tz(clockzone).format('HH')}

                        {moment.tz(clockzone).format(':mm:')}
                      
                        {moment.tz(clockzone).format('ss')}
                        {isTwelve ? <Typography variant="button"><b>{moment.tz(clockzone).format('A')}</b></Typography> : null}
                        
                        </b>
                      </Typography>
                      <Tooltip title="Copy time">
        <CopyToClipboard text={moment.tz(clockzone).format('HH:mm:ss')} className={classes.title}>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15 }} />
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
        
                      </Paper>
                      
                      
                        
                     

                      {/* <Paper elevation={2} className={classes.time}>  */}

                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                         
                      {moment.tz(clockzone).format('DD/MM/yyyy')}   Week {moment.tz(currentzone).format('w')}
        <Tooltip title="Copy Date">
        <CopyToClipboard text={moment.tz(clockzone).format('DD/MM/yyyy')} className={classes.title}>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15 }} />
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
                        <br />
                        {moment.tz(clockzone).format('dddd, LL')}
        <Tooltip title="Copy Day & month">
        <CopyToClipboard text={moment.tz(clockzone).format('dddd, LL')} className={classes.title}>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15 }} />
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
                      <br />
                      Ordinal date {moment.tz(clockzone).format('YYYYDDDD')}
        <Tooltip title="Copy Ordinal/Julian Date">
        <CopyToClipboard text={moment.tz(clockzone).format('YYYYDDDD')} className={classes.title}>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15 }} />
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
                      <br />
                      Unix timestamp {moment.tz(clockzone).unix()}
        <Tooltip title="Copy Unix timestamp">
        <CopyToClipboard text={moment.tz(clockzone).unix()} className={classes.title}>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15 }} />
        </IconButton>
        </CopyToClipboard>
        </Tooltip>

                      </Typography>
                      {/* </Paper> */}
                      <ChangeRemove clockkey={clockkey}/>
                    </Box>
                  </Paper>
                </Grid>
          );
  case 6:
    return (
      <Grid item xs={12} md={matches1140 ? size : 8} className={classes.root}>
      
            <Paper elevation={6} className={classes.paper}>
            <Box display="flex" alignContent="stretch" flexWrap="wrap" alignSelf="stretch" justifyContent="space-between">
            <Paper elevation={2} className={classes.time}> 
            <b> {clockzone.split("/").join(" - ")} </b><br></br>
            {clockitem.city.split(",").join(" ")}<br></br>
                <Typography variant="h2" component="h2">
                  <b>
                  {isTwelve ? moment.tz(clockzone).format('hh:mm:ss'):moment.tz(clockzone).format('HH:mm:ss')}
                  {isTwelve ? <Typography variant="button"><b>{moment.tz(clockzone).format('A')}</b></Typography> : null}
                  </b>
          <Tooltip title="Copy time">
        <CopyToClipboard text={moment.tz(clockzone).format('HH:mm:ss')} className={classes.title}>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15 }} />
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
                </Typography>
                </Paper>
            
            <Typography className={classes.title} color="textSecondary" gutterBottom>
            
            <br />
                  {moment.tz(clockzone).format("UTCZ(zz)")}
                    <br />
                        {moment.tz(clockzone).format('DD/MM/yyyy')}
                        <br />
                        {moment.tz(clockzone).format('dddd')} Week {moment.tz(currentzone).format('w')}
                        <br />
                        {moment.tz(clockzone).format('ll')}
                        <br />
                {moment.tz(clockzone).format("yyyyDDDD")}
                  
                  

                </Typography>
                <ChangeRemove clockkey={clockkey}/>
              </Box>
            </Paper> 
          </Grid>
    );
    default:
          return (
            <Grid item xs={12} md={matches1290 ? size : 6} className={classes.root}>
              
                  <Paper elevation={6} className={classes.paper}>
                  
                  <Box display="flex" alignContent="stretch" flexWrap="wrap" alignSelf="stretch" justifyContent="space-between">
                  <Grid item xs={matches420 ? 8: 12} md={8}>
                  <Paper > 
                  <Typography variant="body2" component="h2" color="textSecondary" className={classes.title}>
                  <b> {clockitem.city.split(",").join(" ")}</b>
                    
                  </Typography>
                  
                  <Typography variant="h3" component="h2" className={classes.time}>
                    
                    <b>    {isTwelve ? moment.tz(clockzone).format('hh:mm:ss'):moment.tz(clockzone).format('HH:mm:ss')}
                    {isTwelve ? <Typography variant="button"><b>{moment.tz(clockzone).format('A')}</b></Typography> : null}
                    </b>
                        <br></br>
                        <Tooltip title="Copy time">
        <CopyToClipboard text={moment.tz(clockzone).format('HH:mm:ss')} className={classes.title}>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15 }} />
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
                      </Typography>
                  </Paper>
              </Grid>
                      
                  
                      <Typography variant="body2" className={classes.title} color="textSecondary" gutterBottom>
                      <ChangeRemove clockkey={clockkey}/> 
                   
                      {moment.tz(clockzone).format("UTCZ(zz)")}
                      <br />
                         {moment.tz(clockzone).format(" DD/MM/yy")}
                        <br />
                        {moment.tz(clockzone).format( "ll")}
                    <br />
                    {moment.tz(clockzone).format('DDDD  ddd')}
                    <br />
                    Week {moment.tz(currentzone).format('w')}
                   

                    </Typography>
                    

                    </Box>
                  </Paper>
                </Grid>
          );
};
}
