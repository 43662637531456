import React, {Component} from "react";
//import Context from "./context";
import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {toggleTwelve} from '../actions/is12Actions';
//import { TOGGLE_DARK_MODE } from "../actions/types";

class Twelve24Switch extends Component  {

render() {
  const isTwelve = this.props.isTwelve;
return ( 
  <Typography component="label" color="textSecondary">
    
  <Grid container alignItems="center" spacing={0}>
    <Grid item>24H</Grid>
    <Grid item>
      <Switch id="24switch" name="24switch"checked={isTwelve} onChange={() => this.props.toggleTwelve()}/>
      </Grid>
          <Grid item>12h</Grid>
        </Grid>
      </Typography>
  )
}
}
const mapStateToProps = state => ({
  isTwelve: state.isTwelve
});
export default connect(mapStateToProps, {toggleTwelve})(Twelve24Switch);