import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Clock from './Clock'
import {Link} from 'react-router-dom';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
//import Box from '@material-ui/core/Box';
//import Button from '@material-ui/core/Button'
//import { max } from 'date-fns';
// import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
//import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import ShareIconSvg from '../SvgIcons/ShareIconSvg';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIconSvg from '../SvgIcons/EditIconSvg';
import CopyIconSvg from '../SvgIcons/CopyIconSvg';
//import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import  moment from 'moment-timezone';
//import Dialog from '@material-ui/core/Dialog';
import AdsInline from './AdsInline';
import ClockzoneComponent from './ClockzoneComponent';
import {useSelector} from 'react-redux';
//import zIndex from '@material-ui/core/styles/zIndex';
//import AdSense from 'react-adsense';
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";


import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

// const ClockzoneComponent  = React.lazy(() => import('./ClockzoneComponent'));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'sticky',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab2: {
    position: 'right',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  clockgrid: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));



function Pageview(props) {
  if ((typeof window !== 'undefined')&&(process.env.NODE_ENV == 'production')) {
    ReactGA.pageview(window.location.pathname + window.location.search);
    }
return null;
}





function Clocklist(props) {
  //const matches720 = useMediaQuery('(min-width:720px)');
  const matches600 = useMediaQuery('(min-width:600px)');
  //const matches1140 = useMediaQuery('(min-width:1140px)');
  return(
    
    props.clockarray.map((array,index) =>
      {
        switch(index){
        case 0:
          return <React.Fragment><Clock key="clockmain" size={matches600 ? 12 : 6} clockkey={index} clockzone={array.timezone} clockitem={array.item}/><Grid item xs={12}><AdsInline page="clock" adskey="adsense"/></Grid></React.Fragment>
        default:
        return <Clock   key={"clock"+index} size={matches600 ? array.size : ((array.size == 4)? 4 : 6 )} clockkey={index} clockzone={array.timezone} clockitem={array.item}/>
        }
      }

    )


  )
}

function zonebase64(number) {
  let rep = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','-','='];
  var num = number;
  let rem = 0;
  let base =[];
  while (num > 0) {
  rem = num % 64;
  base.push(rem);
  num = Math.floor(num /64);
  }
  let textchar = [0,0,0];
  base.map((array,index) => {textchar[index]=(rep[array]);});
  let text = textchar.join("");
  return text;
  }
  




export default function AutoGrid(props) {
  const classes = useStyles();
  //const bull = <span className={classes.bullet}>•</span>;
  const [open, setOpen] = React.useState(false);
  const currentzone = useSelector(state => state.clock.maintimezone);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const clockarray = useSelector(state => state.clock.clockarray);

const [copied, setCopied] = React.useState(false);

const handleCopied = () => {
  setCopied(true);
  setTimeout(() => setCopied(false), 2000);
};

const [accord, setAccord] = React.useState(true);
const handleClick = (event) => {
  
   (accord) ? setAccord(false):setAccord(true);
};
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide key={open+"slide"} in={open}  mountOnEnter unmountOnExit direction="up" ref={ref} {...props} />;
// });
const isTwelve =  useSelector(state => state.isTwelve);
const isDark =  useSelector(state => state.isDark);

var clocksharetext = "C";
clocksharetext =clocksharetext.concat(isTwelve ? 'A': 'M');
clocksharetext =clocksharetext.concat(isDark ? 'D': 'L');
clockarray.map((array,index) =>
(index ==0) ?null:clocksharetext = clocksharetext.concat(zonebase64(array.item.number)).concat((array.size==12)? 8:array.size)

);


  return (
    <div >
      <Pageview key={props.link||'/'}/>
        <Helmet>
        <title>Date & Time .in [{currentzone}]</title>
        <link rel="canonical" href={`https://dateandtime.in/`}></link>
        <meta name="description" content="Date and Time in different timezones. Create your custom clock setup with bookmark / share link. Schedule time between different timzones. Clocks Scheduler Timer Alarm Stopwatch. Timezone, City, UTC, Time, Date, Unix time, Ordinal date/ Julian date, Day of the week, Month."/>
        </Helmet>
      <Grid container direction='column' spacing={2} justify='space-evenly'>
      <Grid item xs={12} container spacing={1}>
      {/* <Clock  size={12} clockkey={-3} clockzone={currentzone}/>
      <Clock  size={4} clockkey={-1} clockzone={currentzone}/>
      <Clock  size={6} clockkey={-2} clockzone={currentzone}/> */}
        <Clocklist key={'clocklist'} clockarray={clockarray}/>
      { (clockarray.length == 1) ? <Grid item xs={12} container spacing={1}><Grid item xs={4}></Grid> <AdsInline page="clock" adskey="infolinks"/></Grid>: null }
      </Grid>
      <Grid item xs={12} container alignItems="flex-start" spacing={1}>
        <Grid item xs={12} sm={4}>

        </Grid>
        <Grid item xs={12} sm={12} >
                  


                      <Button className="App-link" color='secondary'  fullWidth variant='contained' target="_blank" rel="noopener noreferrer">
                      <Typography variant='h4' >Clock     </Typography>&nbsp;&nbsp;&nbsp;
                      <Grid item >
                      
                      <Tooltip title={(copied)? "Link Copied":"Share link for this Clock"}>                
              <CopyToClipboard text={"https://dateandtime.in/share/"+clocksharetext} className={classes.title} onCopy={() => handleCopied(true) }>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <ShareIconSvg style={{ fontSize: 20}}/>
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
        </Grid>
                       
                  
                            
                  </Button>
                  <p>
                  <Box display="flex" alignItems="center" gap={2}>
                  <Typography component="h3" >[{currentzone}]</Typography>&nbsp;
                  <Link  style={{ textDecoration: 'none' }} to="/zones">
                  <Button color="primary" variant="outlined" aria-label="Popular Cities and Timezones" className={classes.fab}>
                  Timezones        
        </Button> 
        </Link> 
        </Box>
        </p>
        </Grid>
        </Grid>

      </Grid>
      <Fab color="secondary" aria-label="add" className={classes.fab} onClick={handleOpen}>
        <AddIcon />
      </Fab>

      <Dialog open={open}
      onBackdropClick={handleClose}
      onClose={() => setOpen(false)}
      keepMounted
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      >
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          <React.Suspense fallback={<div>Loading...</div>}>
            <ClockzoneComponent />
          </React.Suspense>
        </Box>
      </Dialog>


<MuiAccordion square expanded={accord} onChange={handleClick}> 
        <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography color={(accord) ? "textSecondary":"textPrimary"}>{(accord) ? "How to Use:":"Help (?)"}</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <Typography variant="caption" color="textSecondary">
            Click on the <b>+</b> button to add additional clocks. Select timezone/city and select the size of the additional clocks.<p></p>
            Click on <b>X</b> to delete any additional clock. <p></p>
            Click on <EditIconSvg style={{ fontSize: 10 }}/> to change the timezone or size of any clock.(Size of the 1st clock is fixed and cannot be changed) <p></p>
            Use the <ShareIconSvg style={{ fontSize: 10 }}/> icon above to copy the Share link.
            You can bookmark the Share link or share it with others to get back to the same clock setup that you have currently.
            (Share link does not share the Main clock, this is set automatically to the regional Timezone.) <p></p>
            The <CopyIconSvg style={{ fontSize: 10 }}/> icon makes it easier to copy information from the clock to the clipboard.
            If the <CopyIconSvg style={{ fontSize: 10 }}/> icon is not available you can still copy any text from the clockface manually.
          </Typography>
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
}
