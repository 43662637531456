import React from 'react';
import Container from '@material-ui/core/Container';
import AutoGrid from './AutoGrid';
import Schedulerpage from './Schedulerpage';
import ShareRoutes from './ShareRoutes';

import Timerpage from './Timerpage';

import Information from './Information';
import Settings from './Settings';
import Privacy from './Privacy';
import LinksPage from './LinksPage';
import CityRoutes from './CityRoutes';
//import Paper from '@material-ui/core/Paper';
import CookieConsent, { Cookies } from "react-cookie-consent";
//import CityRoutes from './CityRoutes';

//import { Footer } from "mui-layout";

import {BrowserRouter as Router,Link, Route, Switch, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Slide from '@material-ui/core/Slide';


//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import TimerTwoToneIcon from '@material-ui/icons/TimerTwoTone';

import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
//import FlightTwoToneIcon from '@material-ui/icons/FlightTwoTone';
//import * as MuiSwitch from '@material-ui/core/Switch';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
//import ThemeContext from '../ThemeContext';
import DarkLightSwitch from './DarkLightSwitch';
import Twelve24Switch from './Twelve24Switch';
import SocialpageLinks from './SocialpageLinks';
import AlarmSound from './AlarmSound';
import TimerSound from './TimerSound';


// const Timerpage  = React.lazy(() => import('./Timerpage'));

// const Information   = React.lazy(() => import('./Information'));
// const Settings   = React.lazy(() => import('./Settings'));
// const Privacy   = React.lazy(() => import('./Privacy'));
// const CityRoutes    = React.lazy(() => import('./CityRoutes'));
// const ShareRoutes    = React.lazy(() => import('./ShareRoutes'));
// const AutoGrid   = React.lazy(() => import('./AutoGrid'));
// const Schedulerpage   = React.lazy(() => import('./Schedulerpage'));

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawersmall: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


// function LabelBottomNavigation() {
//   const classes = useStyles();
//   const [value, setValue] = React.useState('recents');

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <BottomNavigation value={value} onChange={handleChange} className={classes.root}>
//       <BottomNavigationAction label="Clock" value="recents" icon={<AccessTimeTwoToneIcon />} />
//       <BottomNavigationAction label="Scheduler" value="favorites" icon={<DateRangeTwoToneIcon />} />
//       <BottomNavigationAction label="Timer" value="nearby" icon={<TimerTwoToneIcon />} />
//       <BottomNavigationAction label="Settings" value="folder" icon={<SettingsTwoToneIcon />} />
//       <BottomNavigationAction label="Information" value="recents" icon={<InfoOutlinedIcon />} />
//     </BottomNavigation>
//   );
// }


export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  let timezone = "     ";
  const checked =true;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
        <Hidden xsDown implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          </Hidden>
          <Typography variant="h5"  component="h1" noWrap>
            Date & Time .in  {timezone}
          </Typography>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          
         <DarkLightSwitch/>
         &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          
         <Twelve24Switch/>
            <SocialpageLinks/>
         <AlarmSound/>
         <TimerSound/>
        </Toolbar>
      </AppBar>
      <Hidden xsDown implementation="css">
      <Drawer
        
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
              <Link  style={{ textDecoration: 'none' }}	to="/">
             <Tooltip title={open ? "":"Clocks"} placement="left-end" TransitionComponent={Zoom}><ListItem button key='Clocks'>
              <ListItemIcon><AccessTimeTwoToneIcon /></ListItemIcon>
            <ListItemText primary={<Typography variant="subtitle1" color="textSecondary">Clocks</Typography>} />
            </ListItem>
            </Tooltip>  
            </Link>
            <Link  style={{ textDecoration: 'none' }} to="/scheduler">
            <Tooltip title={open ? "":"Scheduler"}  placement="left-end" TransitionComponent={Zoom}><ListItem button key='Scheduler'>
              <ListItemIcon><DateRangeTwoToneIcon /></ListItemIcon>
              
              <ListItemText primary={<Typography variant="subtitle1" color="textSecondary">Scheduler</Typography>} />
            </ListItem>
            </Tooltip>
            </Link>
            <Link style={{ textDecoration: 'none' }} to="/timer">
            <Tooltip title={open ? "":"Timer"}  placement="left-end" TransitionComponent={Zoom}><ListItem button key='Timer'>
              <ListItemIcon><TimerTwoToneIcon /></ListItemIcon>
              
              <ListItemText primary={<Typography variant="subtitle1" color="textSecondary">Timer</Typography>} />
            </ListItem>
            </Tooltip>
            </Link>
        </List>
        <Divider />
        <List>
        {/* <Link style={{ textDecoration: 'none' }} to="/settings">
          <Tooltip title={open ? "":"Settings"}  placement="left-end"><ListItem button key='Settings'>
              <ListItemIcon><SettingsTwoToneIcon /></ListItemIcon>
              
              <ListItemText primary={<Typography variant="subtitle1" color="textSecondary">Settings</Typography>} />
            </ListItem>
            </Tooltip>
          </Link>    */}
          <Link style={{ textDecoration: 'none' }} to="/info">
          <Tooltip title={open ? "":"Information"} placement="left-end" TransitionComponent={Zoom}><ListItem button key='Information'>
              <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
              
              <ListItemText primary={<Typography variant="subtitle1" color="textSecondary">Information</Typography>} />
            </ListItem>
            </Tooltip>
          </Link>       
        </List>
      </Drawer>
      </Hidden>
      
      

      <main className={classes.content}>
        <div className={classes.toolbar} />
    
      
      
      <Container disableGutters>
      <div>
        {/* <Router> */}
      <React.Suspense fallback={<div>Loading...</div>}>

      {/* <Slide key={location.pathname} direction="right" in={checked} mountOnEnter unmountOnExit>
      <Route render={(location) => {
      return ( */}
        <Switch location={location}>
            
            <Route path="/" exact component={AutoGrid}/>
            <Route path="/scheduler" exact component={Schedulerpage}/>           
            <Route path="/timer" exact component={Timerpage}/>
            <Route path="/info" exact component={Information}/>
            <Route path="/settings" exact component={Settings}/>
            <Route path="/privacy" exact component={Privacy}/>
            <Route path="/zones" exact component={LinksPage}/>
            <Route path="/share/*" location={location} component={ShareRoutes}/>
            <Route path="/*" location={location} component={CityRoutes}/>
           
        </Switch>
        {/* )}}/>
        </Slide> */}
        </React.Suspense>
        {/* </Router> */}
        </div>
        
        </Container>
        &nbsp;

        {/* <Divider /> */}
        &nbsp;
        <Hidden xsDown implementation="css" className={classes.drawersmall}>
        <Grid item xs={12} container spacing={0}>
        <Divider />
        <Toolbar>
          {/* <Typography>
            Footer
          </Typography> */}
        </Toolbar>
        </Grid>
        </Hidden>
        <Hidden smUp implementation="css">
        <Toolbar/>
          

        <Drawer
        anchor="bottom"
        variant="permanent"
        
        >
        <Divider />

        <List>
        <Grid
  container
  direction="row"
  justify="space-around"
  alignItems="flex-end"
>

              <Link  style={{ textDecoration: 'none' }}	to="/">
             <Tooltip title="Clocks" placement="top"><ListItem button key='Clocks'>
              <ListItemIcon><AccessTimeTwoToneIcon /></ListItemIcon>
            
            </ListItem>
            </Tooltip>  
            </Link>
            <Link  style={{ textDecoration: 'none' }} to="/scheduler">
            <Tooltip title="Scheduler" placement="top"><ListItem button key='Scheduler'>
              <ListItemIcon><DateRangeTwoToneIcon /></ListItemIcon>
              
              
            </ListItem>
            </Tooltip>
            </Link>
            <Link style={{ textDecoration: 'none' }} to="/timer">
            <Tooltip title="Timer" placement="top"><ListItem button key='Timer'>
              <ListItemIcon><TimerTwoToneIcon /></ListItemIcon>
              
              
            </ListItem>
            </Tooltip>
            </Link>
            
        
        <Divider />
        
      {/* <Link style={{ textDecoration: 'none' }} to="/settings">
          <Tooltip title="Settings"  placement="top"><ListItem button key='Settings'>
              <ListItemIcon><SettingsTwoToneIcon /></ListItemIcon>
              
              
            </ListItem>
            </Tooltip>
          </Link>    */}
          <Link style={{ textDecoration: 'none' }} to="/info">
          <Tooltip title="Information" placement="top"><ListItem button key='Information'>
              <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
              
              
            </ListItem>
            </Tooltip>
          </Link>    
          </Grid> 
        </List>
       
  
      </Drawer>
      </Hidden>
      
        
      </main>
    </div>
  );
}
